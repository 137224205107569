import { useAuthStore } from "~/stores/AuthStore";
import { useLoginStore } from "~/stores/LoginStore";

export default defineNuxtRouteMiddleware(async () => {
  const authStore = useAuthStore();
  const loginStore = useLoginStore();
  await authStore.checkAuthState();
  if (!authStore.token) {
    return navigateTo("/");
  }
  if (!loginStore.isAdmin()) {
    return navigateTo("/");
  }
});
